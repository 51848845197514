import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import qs from "query-string";
import { MREQ_AMPLITUDE } from "__presets";
import { NavBar } from "~/packages/ui/mobile";
import {
    Booking,
    BrandList,
    ReqEstDone,
    ReqEstStep1,
    ReqEstStep2,
    ViewReceived,
    ViewSentEst,
    ViewShop,
    ViewShopEst
} from "~/containers";
import * as rt from "./routes";
import useAmplitude from "./amplitude";

function App() {
    const [navToolbar, setNavToolbar] = useState({});
    const history = useHistory();
    const { search } = useLocation();
    const amplitude = useAmplitude();

    const navBarProps = {
        toolbar: {
            title: navToolbar.title,
            left: navToolbar.left,
            right: navToolbar.right
        },
        onClickLeft: navToolbar.left ? () => history.goBack() : undefined
    };

    useEffect(() => {
        const { promocode = "" } = qs.parse(search);
        amplitude.logEvent(MREQ_AMPLITUDE.viewMreqRequest, { promocode });
    }, [amplitude, search]);
    function base64UrlToBase64(base64Url) {
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        while (base64.length % 4) {
            base64 += "=";
        }
        return base64;
    }
    function decrypt(accidentInquiryNumber) {
        try {
            const encryptedText = accidentInquiryNumber;
            const key = "kbinsur_2_cardoc";
            const iv = "kbinsur_2_cardoc";
            // Base64URL을 Base64로 변환
            const base64 = base64UrlToBase64(encryptedText);

            // WordArray로 변환
            const ciphertext = CryptoJS.enc.Base64.parse(base64);

            // key와 iv를 WordArray로 변환
            const keyBytes = CryptoJS.enc.Utf8.parse(key);
            const ivBytes = CryptoJS.enc.Utf8.parse(iv);

            // 복호화 시도
            const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, keyBytes, {
                iv: ivBytes,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            // 결과를 UTF-8 문자열로 변환
            const result = decrypted.toString(CryptoJS.enc.Utf8);
            return result;
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const promocode = searchParams.get("promocode") || "";
        const accReqNoEnc = searchParams.get("accReqNoEnc");
        const accReqNo = searchParams.get("accReqNo");
        const accidentInquiryNumber = accReqNo || accReqNoEnc;
        const encodedNumber = promocode === "KBINS" ? decrypt(accidentInquiryNumber) : "";
        // Repair Renewal
        window.location.href = `${process.env.REPAIR_URL}/my/cars?referral-code=${promocode}_${encodedNumber}`;
    }, [search]);
    return (
        <>
            {navToolbar.title && <NavBar {...navBarProps} />}

            <Switch>
                <Route exact path={rt.REQ_EST_STEP1} render={() => <ReqEstStep1 setNavToolbar={setNavToolbar} />} />

                <Route path={rt.REQ_EST_STEP2} render={() => <ReqEstStep2 setNavToolbar={setNavToolbar} />} />

                <Route path={rt.REQ_EST_DONE} render={() => <ReqEstDone setNavToolbar={setNavToolbar} />} />

                <Route path={rt.BRAND_LIST} render={() => <BrandList setNavToolbar={setNavToolbar} />} />

                <Route path={rt.VIEW_RECEIVED} render={() => <ViewReceived setNavToolbar={setNavToolbar} />} />

                <Route path={rt.BOOKING} render={() => <Booking setNavToolbar={setNavToolbar} />} />

                <Route path={rt.VIEW_SHOP} render={() => <ViewShop setNavToolbar={setNavToolbar} />} />

                <Route path={rt.VIEW_SHOP_EST} render={() => <ViewShopEst setNavToolbar={setNavToolbar} />} />

                <Route path={rt.VIEW_SENT_EST} render={() => <ViewSentEst setNavToolbar={setNavToolbar} />} />
            </Switch>
        </>
    );
}

export default App;
